import { environment, getEnvironment } from "./utilities/utils";

let API_ROOT = null;
let DEFAULT_BRANCH_LINK = "https://link.workmate.asia/VZIzn67mP3";
let BRANCH_KEY = null;
let IOS_BRANCH_DEFAULT_REDIRECT = null;
let ANDROID_BRANCH_DEFAULT_REDIRECT = null;
const DESKTOP_BRANCH_DEFAULT_REDIRECT = "https://www.workmate.asia";

if (getEnvironment() === environment.PRODUCTION) {
  API_ROOT = "https://api.workmate.asia/v1";
  BRANCH_KEY = "key_live_jeGERNFxGS0HxzxxB4OZLhcaFreRC9O0";
  IOS_BRANCH_DEFAULT_REDIRECT =
    "https://apps.apple.com/sg/app/workmate-for-workers/id1086845851";
  ANDROID_BRANCH_DEFAULT_REDIRECT =
    "https://play.google.com/store/apps/details?id=com.helpster.partner";
} else {
  API_ROOT = "https://api.staging.workmate.asia/v1";
  BRANCH_KEY = "key_test_dhGBJGsvPM2NAFtvD3SXIggazAgQy6QU";
  DEFAULT_BRANCH_LINK = "https://link-dev.workmate.asia/QeEAWxKywnb";
  IOS_BRANCH_DEFAULT_REDIRECT = "https://testflight.apple.com/join/9z1OHlxS";
  ANDROID_BRANCH_DEFAULT_REDIRECT =
    "https://play.google.com/store/apps/details?id=com.helpster.partner.develop";
}

// TODO: Default business signup link to production signup.
// Gatsby is build-time. && getEnvironment here always returns !== PRODUCTION
// One way to accurately get environment is through NETLIFY env variables that is read during build time.
const BUSINESS_SIGNUP_LINK = "https://dashboard.workmate.asia/signup";
const BUSINESS_LOGIN_LINK = "https://dashboard.workmate.asia/login";
const JOBS_PUBLIC_WEBSITE_LINK = "https://jobs.workmate.asia";
const SG_JOBS_PUBLIC_WEBSITE_LINK = "https://link.workmate.asia/fBNkDjDSKub";

export {
  API_ROOT,
  DEFAULT_BRANCH_LINK,
  BRANCH_KEY,
  BUSINESS_SIGNUP_LINK,
  BUSINESS_LOGIN_LINK,
  JOBS_PUBLIC_WEBSITE_LINK,
  IOS_BRANCH_DEFAULT_REDIRECT,
  ANDROID_BRANCH_DEFAULT_REDIRECT,
  DESKTOP_BRANCH_DEFAULT_REDIRECT,
};

export const endpoints = {
  partners: `${API_ROOT}/partners/`,
  staffRequests: `${API_ROOT}/staff-requests/`,
  positions: `${API_ROOT}/positions/`,
  countries: `${API_ROOT}/countries/`,
};

export const HUBSPOT_PORTAL_ID = "5230939";
export const HUBSPOT_FORM_ID = "66cffe5f-ec15-4b51-bd80-fb7ad860d299";

// the business sign up links are hardcoded for now. Ask PMM for details
export const businessSignupLinks = {
  "en-gb": `${BUSINESS_SIGNUP_LINK}?lng=en`,
  id: `https://business.workmate.asia/id/id-lets-talk`,
  th: `https://business.workmate.asia/th-th/lets-talk`,
};

export const jobsPublicWebsiteLinks = {
  "en-gb": `${SG_JOBS_PUBLIC_WEBSITE_LINK}`,
  id: `${JOBS_PUBLIC_WEBSITE_LINK}`,
  th: `${JOBS_PUBLIC_WEBSITE_LINK}`,
};

export const workmateSolutionsHubspotFormLinks = {
  "en-gb": "https://bit.ly/3DhBeno",
  id: "https://bit.ly/3uB8eTB",
  th: "https://bit.ly/3wSoCSe",
};

export const helpCentreLinks = {
  "en-gb": "https://workmateasia.zendesk.com/hc/en-us",
  id: "https://workmateasia.zendesk.com/hc/id-id",
  th: "https://workmateasia.zendesk.com/hc/th",
};

export const helpCentreForWorkersLinks = {
  "en-gb":
    "https://workmateasia.zendesk.com/hc/en-us/categories/900000158806-Worker",
  id: "https://workmateasia.zendesk.com/hc/id-id/categories/900000158806-Worker",
  th: "https://workmateasia.zendesk.com/hc/th/categories/900000158806-Worker",
};

export const jobBoardLink = "https://www.careers-page.com/workmate";
